import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, HostListener, NO_ERRORS_SCHEMA, OnDestroy, OnInit, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedService } from '../shared.service';
import { TemplateViewerService } from '../template-viewer/template-viewer.service';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { environment } from '../../environments/environment';
import { TemplateTaggingReportConceptFactDialogDialogComponent } from '../template-tagging-report-concept-fact-dialog/template-tagging-report-concept-fact-dialog.component';
import { MatProgressSpinnerModule, ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface FilterSection {
  name: string;
  show: boolean;
  list: any[];
  noIssueMessage: string;
}
@Component({
  selector: 'app-template-tagging-data-point',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule, ClipboardModule, MatTooltipModule, MatDialogModule, MatProgressSpinnerModule,
    FormsModule, ReactiveFormsModule, MatExpansionModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [TemplateViewerService],
  templateUrl: './template-tagging-data-point.component.html',
  styleUrls: ['./template-tagging-data-point.component.scss', '../common.scss'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('expanded', style({
        height: '*',
        opacity: 1
      })),
      transition('collapsed <=> expanded', [
        animate('300ms ease-out')
      ])
    ])
  ]
})
export class TemplateTaggingDataPointComponent implements OnInit {


  templateData: any = [];
  @ViewChildren('contentRefs') contentRefs!: QueryList<ElementRef>;
  zoomLevel: string = 'scale(1)';
  zoomFactor: number = 1;
  factLabelFilter: string = 'Fact Properties';
  currentIndex: number = 0;
  filterSections: FilterSection[] = [
    { name: 'Validations', show: false, list: [], noIssueMessage: 'No validation issues found' },
    { name: 'References', show: false, list: [], noIssueMessage: 'No references available' },
    { name: 'Calculation', show: false, list: [], noIssueMessage: 'No calculations required' },
    { name: 'Footnotes', show: false, list: [], noIssueMessage: 'No footnotes provided' },
    { name: 'Sections', show: false, list: [], noIssueMessage: 'No sections to display' }
  ];

  showSearchInput: boolean = false;
  searchQuery: string = '';
  activeIcon: string = '';
  showOutlineList: boolean = false;
  searchListShowHide: boolean = false;
  searchList: any[] = [];

  showFilterOptions: boolean = false;
  selectedConceptType: string = 'All';
  selectedDate: string = 'all';
  impactMaterialTopics: any;
  nonMaterialTopics: any;
  financialMaterialTopics: any;
  impactFinancialMaterialTopics: any;

  environmentalMaterial: any[] = [];
  socialMaterial: any[] = [];
  governanceMaterial: any[] = [];
  environmentalNonMaterial: any[] = [];
  socialNonMaterial: any[] = [];
  governanceNonMaterial: any[] = [];
  checkboxChecked = false;
  highlightXBRLElements: boolean = false;
  hoveredIndices: (number | null)[] = [];
  copiedText: string | null = null;
  selectedOutlineIndex: number | null = null;
  selectedOutlineIndexForSearch: number | null = null;
  @ViewChild('contentContainer') contentContainer!: ElementRef;
  @ViewChild('content') contentTemplate!: TemplateRef<any>;
  factValue: any;
  currentContent: string | null = null;
  showTooltip: boolean = false;
  isChecked: boolean = false;
  uniqueDates: Date[] = [];
  selectedTopicId: string | null = null;
  private highlightTimeout: any;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  animatedText: string[] = [];
  panelStates: { [key: string]: boolean } = {};
  expandedPanelId: string | null = null; 
  currentlyExpandedPanel: string | null = null; 
  @ViewChild('htmlContentContainer') htmlContentContainer!: ElementRef;
  iframeHeight: number = 0;   
  tooltipMatch: string='';

  constructor(private sharedService: SharedService, private templateService: TemplateViewerService, private dialog: MatDialog,
    private cdr: ChangeDetectorRef,private sanitizer: DomSanitizer,
  ) {    this.updateIframeHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateIframeHeight();
  }
  updateIframeHeight() {
    const windowHeight = window.innerHeight;
    this.iframeHeight = windowHeight ; 
  }

  ngOnInit() {
    this.animatedText = this.splitTextIntoLetters("Your report is sanitizing...");

    this.getTemplateContent();
    // this.getMateriality()
    // this.templateData=this.sharedService.getDataPoints();
    // console.log('templateData',this.templateData)
  }


  splitTextIntoLetters(text: string): string[] {
    return text.split(' ');
  }
  getTemplateContent() {
    this.templateService.getTopicsDataCollection(1, 1).subscribe({
      next: (response) => {
        console.log('response', response)
        this.templateData = response;
        this.searchList = [...this.templateData];
        // console.log('topic' + this.templateData);
        // console.log(this.searchList);
        this.currentContent = null;

        //expand collapse start
        this.panelStates = {};
        this.templateData.forEach((content:any) => {
          content.topics.forEach((topic:any) => {
            topic.dataPointGroups.forEach((dataPointGrp:any) => {
              this.panelStates[dataPointGrp.id] = false; 
            });
          });
        });
      },
      error: (error) => {
        // console.error('Error fetching topics ' + error);
      }
    });


  }

  generateUniqueDates() {
    const datesSet = new Set<string>();
    this.templateData.forEach((item: any) => {
      const dateStr = item.startDate.toDateString();
      datesSet.add(dateStr);
    });
    this.uniqueDates = Array.from(datesSet).map(dateStr => new Date(dateStr));
  }
  scrollToTopic(dataPointId: any): void {
    console.log(dataPointId)
    this.selectedTopicId = dataPointId;
    this.selectedOutlineIndexForSearch = dataPointId;
    this.selectedOutlineIndex = dataPointId;
    const targetElement = document.getElementById(dataPointId);
    if (targetElement) {
      this.togglePanel(dataPointId)
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (this.highlightTimeout) {
        clearTimeout(this.highlightTimeout);
      }
      this.highlightTimeout = setTimeout(() => {
        this.selectedTopicId = null;
      }, 1000);
    } else {
      // console.log('Element not found with ID', dataPointId);
    }
  }
  

  toggleSearch() {
    this.currentContent = null;
    this.activeIcon = 'search';
    this.showSearchInput = !this.showSearchInput;
    this.searchListShowHide = false;
    this.showOutlineList = false;
  }
  toggleSearchOutline() {
    this.currentContent = null;
    this.activeIcon = 'outlineUnavailable';
    this.showOutlineList = !this.showOutlineList;
    this.searchListShowHide = false;
    this.showSearchInput = false
  }
  toggleSearchFact() {
    this.currentContent = null;
    this.activeIcon = 'info';
    this.searchListShowHide = true;
    this.showSearchInput = false;
    this.showOutlineList = false
  }

  onSearchChange() {
    // console.log('searchQuery', this.searchQuery);
    this.currentContent = null;
    if (this.searchQuery) {
      const filteredList = this.templateData.filter((item: any) => {
        return item.topics.some((topic: any) => {
          return topic.dataPointGroups.some((dataPointGrp: any) => {
            // return dataPointGrp.dataPoints.some((dataPoint: any) => {
              return dataPointGrp?.name?.toLowerCase().includes(this.searchQuery.toLowerCase());
            // });
          });
        });
      });

      // console.log(filteredList);
      this.searchList = [...filteredList];
      this.scrollToFirstMatchingDataPoint();

    } else {
      this.searchList = [...this.templateData];
    }
    // console.log('searchList', this.searchList);
    // this.cdr.detectChanges();
  }
  scrollToFirstMatchingDataPoint() {
    for (const content of this.searchList) {
      for (const topic of content.topics) {
        for (const dataPointGrp of topic.dataPointGroups) {
          // for (const dataPoint of dataPointGrp.dataPoints) {
            if (dataPointGrp.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
              this.scrollToTopic(dataPointGrp.id);
              return;
            }
          // }
        }
      }
    }
  }

  openSearchFactList(index: number, search: any) {
    // console.log('search', search)
    this.searchListShowHide = true;
    this.showSearchInput = false;
    this.navigateToSection(index);
    this.addTopicToSection(search, search.value)

  }


  navigateToSection(index: number) {
    const elements = this.contentRefs.toArray();
    if (elements[index]) {
      elements[index].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }



  onDateChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedDate = selectElement.value;

    if (selectedDate === 'all') {
      this.searchList = [...this.templateData];
    } else {
      const date = new Date(selectedDate);
      this.searchList = this.templateData.filter((item: any) =>
        item.startDate.toDateString() === date.toDateString()
      );
    }
    // console.log('Filtered searchList', this.searchList);
  }

  toggleFilter() {
    this.showFilterOptions = !this.showFilterOptions;
  }
  resetFilters() {
    this.selectedConceptType = 'All';
    this.selectedDate = 'all';
    this.showFilterOptions = false;
    this.searchList = [...this.templateData];
  }

  onCheckboxChange(event: Event): void {
    this.checkboxChecked = (event.target as HTMLInputElement).checked;

    const checkbox = event.target as HTMLInputElement;
    this.highlightXBRLElements = checkbox.checked;
  }

  getHighlightClass() {
    return this.highlightXBRLElements ? 'highlight' : '';
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.copiedText = text;
      setTimeout(() => this.copiedText = null, 3000);
    });
  }

  togglefilterSection(sectionToToggle: any) {
    this.currentContent = null;
    this.filterSections.forEach(section => {
      if (section === sectionToToggle) {
        section.show = !section.show;
      } else {
        section.show = false;
      }
    });
  }

  // formatSentences(input: string): string[] {
  //   if (!input || typeof input !== 'string') {
  //     return [];
  //   }
  //   return input.split('.').map(sentence => sentence.trim()).filter(sentence => sentence);
  // }



  formatSentences(input: string): string[] {
    if (!input || typeof input !== 'string') {
      return [];
    }

    return input.split('.').map(sentence => {
      let trimmedSentence = sentence.trim();

      if (trimmedSentence) {
        trimmedSentence = this.applyDynamicTags(trimmedSentence);
      }

      return trimmedSentence;
    }).filter(sentence => sentence);
  }
  applyDynamicTags(sentence: string): string {
    // const environmentKeywords = [
    //   'carbon footprint', 'carbon emissions', 'energy consumption', 'renewable energy', 'climate change', 'water usage', 
    //   'resource conservation', 'sustainability', 'greenhouse gas emissions', 'energy efficiency', 'emissions reduction',
    //   'eco-friendly', 'clean energy', 'biodiversity', 'green technology', 'carbon neutral', 'waste management', 'pollution control',
    //   'climate adaptation', 'environmental protection', 'carbon credits', 'environmental impact', 'carbon offset', 'sustainable energy',
    //   'water conservation', 'energy conservation', 'zero emissions', 'recycling', 'green initiatives'
    // ];

    // const socialKeywords = [
    //   'community development', 'employee welfare', 'diversity and inclusion', 'social impact', 'labor rights', 'human rights',
    //   'philanthropy', 'employee satisfaction', 'workplace diversity', 'gender equality', 'social responsibility', 'inclusive policies',
    //   'health and safety', 'fair wages', 'child labor', 'forced labor', 'work-life balance', 'equal opportunity', 'education and training',
    //   'corporate social responsibility (CSR)', 'volunteering', 'human capital development', 'social equity', 'living wages', 'disability inclusion',
    //   'anti-discrimination', 'employee empowerment', 'workplace harassment', 'fair treatment', 'community engagement'
    // ];

    // const governanceKeywords = [
    //   'corporate governance', 'board diversity', 'executive compensation', 'compliance', 'anti-corruption', 'shareholder rights',
    //   'transparency', 'risk management', 'audit committee', 'internal controls', 'accountability', 'board independence', 'board structure',
    //   'ethical conduct', 'corporate ethics', 'regulatory compliance', 'stakeholder engagement', 'whistleblowing', 'anti-money laundering (AML)',
    //   'anti-bribery', 'financial oversight', 'performance-based compensation', 'conflict of interest', 'risk assessment', 'stakeholder governance',
    //   'company policies', 'business ethics', 'integrity', 'executive pay', 'ownership structure', 'corporate responsibility', 'board effectiveness'
    // ];

    // const generalDisclosureKeywords = [
    //   'financial disclosures', 'risk disclosures', 'business practices', 'corporate policies', 'audit', 'governance structure', 'regulatory compliance',
    //   'financial performance', 'management reporting', 'disclosure standards', 'accounting policies', 'tax disclosure', 'legal disclosures',
    //   'annual report', 'risk management disclosures', 'management’s discussion and analysis (MD&A)', 'financial reporting', 'SEC filings',
    //   'financial statements', 'quarterly earnings', 'audit report', 'external audit', 'internal audit', 'accounting procedures', 'audit opinion',
    //   'management report', 'investment strategy', 'disclosure framework', 'business strategy', 'corporate reporting', 'compliance statement',
    //   'economic impact', 'legal compliance', 'disclosure practices', 'government regulations'
    // ];

    // if (environmentKeywords.some(keyword => sentence.toLowerCase().includes(keyword))) {
    //   console.log(sentence)
    //   if (sentence.toLowerCase().includes('carbon footprint') || sentence.toLowerCase().includes('carbon emissions')) {
    //     return `<carbonFootprint>${sentence}</carbonFootprint>`; 
    //   }

    //   if (sentence.toLowerCase().includes('energy consumption') || sentence.toLowerCase().includes('renewable energy')) {
    //     return `<energyConsumption>${sentence}</energyConsumption>`; 
    //   }

    //   if (sentence.toLowerCase().includes('sustainability') || sentence.toLowerCase().includes('greenhouse gas emissions')) {
    //     return `<sustainabilityEfforts>${sentence}</sustainabilityEfforts>`; 
    //   }

    //   if (sentence.toLowerCase().includes('water usage') || sentence.toLowerCase().includes('resource conservation')) {
    //     return `<resourceConservation>${sentence}</resourceConservation>`; 
    //   }
    // }

    // if (socialKeywords.some(keyword => sentence.toLowerCase().includes(keyword))) {
    //   if (sentence.toLowerCase().includes('community development') || sentence.toLowerCase().includes('social impact')) {
    //     return `<communityDevelopment>${sentence}</communityDevelopment>`; 
    //   }

    //   if (sentence.toLowerCase().includes('employee welfare') || sentence.toLowerCase().includes('employee satisfaction')) {
    //     return `<employeeWelfare>${sentence}</employeeWelfare>`; 
    //   }

    //   if (sentence.toLowerCase().includes('diversity and inclusion') || sentence.toLowerCase().includes('labor rights')) {
    //     return `<diversityInclusion>${sentence}</diversityInclusion>`;
    //   }
    // }

    // if (governanceKeywords.some(keyword => sentence.toLowerCase().includes(keyword))) {
    //   if (sentence.toLowerCase().includes('corporate governance') || sentence.toLowerCase().includes('board diversity')) {
    //     return `<corporateGovernance>${sentence}</corporateGovernance>`; 
    //   }

    //   if (sentence.toLowerCase().includes('executive compensation') || sentence.toLowerCase().includes('shareholder rights')) {
    //     return `<executiveCompensation>${sentence}</executiveCompensation>`;
    //   }

    //   if (sentence.toLowerCase().includes('anti-corruption') || sentence.toLowerCase().includes('risk management')) {
    //     return `<antiCorruption>${sentence}</antiCorruption>`;
    //   }
    // }

    // if (generalDisclosureKeywords.some(keyword => sentence.toLowerCase().includes(keyword))) {
    //   if (sentence.toLowerCase().includes('financial disclosures') || sentence.toLowerCase().includes('risk disclosures')) {
    //     return `<financialDisclosure>${sentence}</financialDisclosure>`; 
    //   }

    //   if (sentence.toLowerCase().includes('business practices') || sentence.toLowerCase().includes('corporate policies')) {
    //     return `<businessPractices>${sentence}</businessPractices>`;
    //   }

    //   if (sentence.toLowerCase().includes('audit') || sentence.toLowerCase().includes('regulatory compliance')) {
    //     return `<audit>${sentence}</audit>`;
    //   }
    // }

    // return sentence; 

    const environmentKeywords = [
      'carbon footprint', 'carbon emissions', 'energy consumption', 'renewable energy', 'climate change', 'water usage', 
      'resource conservation', 'sustainability', 'greenhouse gas emissions', 'energy efficiency', 'emissions reduction',
      'eco-friendly', 'clean energy', 'biodiversity', 'green technology', 'carbon neutral', 'waste management', 'pollution control',
      'climate adaptation', 'environmental protection', 'carbon credits', 'environmental impact', 'carbon offset', 'sustainable energy',
      'water conservation', 'energy conservation', 'zero emissions', 'recycling', 'green initiatives'
    ];
    
    const socialKeywords = [
      'community development', 'employee welfare', 'diversity and inclusion', 'social impact', 'labor rights', 'human rights',
      'philanthropy', 'employee satisfaction', 'workplace diversity', 'gender equality', 'social responsibility', 'inclusive policies',
      'health and safety', 'fair wages', 'child labor', 'forced labor', 'work-life balance', 'equal opportunity', 'education and training',
      'corporate social responsibility (CSR)', 'volunteering', 'human capital development', 'social equity', 'living wages', 'disability inclusion',
      'anti-discrimination', 'employee empowerment', 'workplace harassment', 'fair treatment', 'community engagement'
    ];
    
    const governanceKeywords = [
      'corporate governance', 'board diversity', 'executive compensation', 'compliance', 'anti-corruption', 'shareholder rights',
      'transparency', 'risk management', 'audit committee', 'internal controls', 'accountability', 'board independence', 'board structure',
      'ethical conduct', 'corporate ethics', 'regulatory compliance', 'stakeholder engagement', 'whistleblowing', 'anti-money laundering (AML)',
      'anti-bribery', 'financial oversight', 'performance-based compensation', 'conflict of interest', 'risk assessment', 'stakeholder governance',
      'company policies', 'business ethics', 'integrity', 'executive pay', 'ownership structure', 'corporate responsibility', 'board effectiveness'
    ];
    
    const generalDisclosureKeywords = [
      'financial disclosures', 'risk disclosures', 'business practices', 'corporate policies', 'audit', 'governance structure', 'regulatory compliance',
      'financial performance', 'management reporting', 'disclosure standards', 'accounting policies', 'tax disclosure', 'legal disclosures',
      'annual report', 'risk management disclosures', 'management’s discussion and analysis (MD&A)', 'financial reporting', 'SEC filings',
      'financial statements', 'quarterly earnings', 'audit report', 'external audit', 'internal audit', 'accounting procedures', 'audit opinion',
      'management report', 'investment strategy', 'disclosure framework', 'business strategy', 'corporate reporting', 'compliance statement',
      'economic impact', 'legal compliance', 'disclosure practices', 'government regulations'
    ];
    
    
      if (environmentKeywords.some(keyword => sentence.toLowerCase().includes(keyword))) {
        if (sentence.toLowerCase().includes('carbon footprint') || sentence.toLowerCase().includes('carbon emissions')) {
          return `<carbonFootprint>${sentence}</carbonFootprint>`;
        }
    
        if (sentence.toLowerCase().includes('energy consumption') || sentence.toLowerCase().includes('renewable energy')) {
          return `<energyConsumption>${sentence}</energyConsumption>`;
        }
    
        if (sentence.toLowerCase().includes('sustainability') || sentence.toLowerCase().includes('greenhouse gas emissions')) {
          return `<sustainabilityEfforts>${sentence}</sustainabilityEfforts>`;
        }
    
        if (sentence.toLowerCase().includes('water usage') || sentence.toLowerCase().includes('resource conservation')) {
          return `<resourceConservation>${sentence}</resourceConservation>`;
        }
    
        if (sentence.toLowerCase().includes('energy efficiency') || sentence.toLowerCase().includes('emissions reduction')) {
          return `<energyEfficiency>${sentence}</energyEfficiency>`;
        }
    
        if (sentence.toLowerCase().includes('eco-friendly') || sentence.toLowerCase().includes('clean energy')) {
          return `<ecoFriendly>${sentence}</ecoFriendly>`;
        }
    
        if (sentence.toLowerCase().includes('biodiversity') || sentence.toLowerCase().includes('green technology')) {
          return `<biodiversity>${sentence}</biodiversity>`;
        }
    
        if (sentence.toLowerCase().includes('carbon neutral') || sentence.toLowerCase().includes('waste management')) {
          return `<carbonNeutral>${sentence}</carbonNeutral>`;
        }
    
        if (sentence.toLowerCase().includes('pollution control') || sentence.toLowerCase().includes('climate adaptation')) {
          return `<pollutionControl>${sentence}</pollutionControl>`;
        }
    
        if (sentence.toLowerCase().includes('environmental protection') || sentence.toLowerCase().includes('carbon credits')) {
          return `<environmentalProtection>${sentence}</environmentalProtection>`;
        }
    
        if (sentence.toLowerCase().includes('environmental impact') || sentence.toLowerCase().includes('carbon offset')) {
          return `<environmentalImpact>${sentence}</environmentalImpact>`;
        }
    
        if (sentence.toLowerCase().includes('sustainable energy') || sentence.toLowerCase().includes('water conservation')) {
          return `<sustainableEnergy>${sentence}</sustainableEnergy>`;
        }
    
        if (sentence.toLowerCase().includes('energy conservation') || sentence.toLowerCase().includes('zero emissions')) {
          return `<energyConservation>${sentence}</energyConservation>`;
        }
    
        if (sentence.toLowerCase().includes('recycling') || sentence.toLowerCase().includes('green initiatives')) {
          return `<recycling>${sentence}</recycling>`;
        }
      }
    
      // Social-related keywords
      if (socialKeywords.some(keyword => sentence.toLowerCase().includes(keyword))) {
        if (sentence.toLowerCase().includes('community development') || sentence.toLowerCase().includes('employee welfare')) {
          return `<communityDevelopment>${sentence}</communityDevelopment>`;
        }
    
        if (sentence.toLowerCase().includes('diversity and inclusion') || sentence.toLowerCase().includes('social impact')) {
          return `<diversityInclusion>${sentence}</diversityInclusion>`;
        }
    
        if (sentence.toLowerCase().includes('labor rights') || sentence.toLowerCase().includes('human rights')) {
          return `<laborRights>${sentence}</laborRights>`;
        }
    
        if (sentence.toLowerCase().includes('philanthropy') || sentence.toLowerCase().includes('employee satisfaction')) {
          return `<philanthropy>${sentence}</philanthropy>`;
        }
    
        if (sentence.toLowerCase().includes('workplace diversity') || sentence.toLowerCase().includes('gender equality')) {
          return `<workplaceDiversity>${sentence}</workplaceDiversity>`;
        }
    
        if (sentence.toLowerCase().includes('social responsibility') || sentence.toLowerCase().includes('inclusive policies')) {
          return `<socialResponsibility>${sentence}</socialResponsibility>`;
        }
    
        if (sentence.toLowerCase().includes('health and safety') || sentence.toLowerCase().includes('fair wages')) {
          return `<healthAndSafety>${sentence}</healthAndSafety>`;
        }
    
        if (sentence.toLowerCase().includes('child labor') || sentence.toLowerCase().includes('forced labor')) {
          return `<laborIssues>${sentence}</laborIssues>`;
        }
    
        if (sentence.toLowerCase().includes('work-life balance') || sentence.toLowerCase().includes('equal opportunity')) {
          return `<workLifeBalance>${sentence}</workLifeBalance>`;
        }
    
        if (sentence.toLowerCase().includes('education and training') || sentence.toLowerCase().includes('corporate social responsibility')) {
          return `<educationAndTraining>${sentence}</educationAndTraining>`;
        }
      }
    
      // Governance-related keywords
      if (governanceKeywords.some(keyword => sentence.toLowerCase().includes(keyword))) {
        if (sentence.toLowerCase().includes('corporate governance') || sentence.toLowerCase().includes('board diversity')) {
          return `<corporateGovernance>${sentence}</corporateGovernance>`;
        }
    
        if (sentence.toLowerCase().includes('executive compensation') || sentence.toLowerCase().includes('compliance')) {
          return `<executiveCompensation>${sentence}</executiveCompensation>`;
        }
    
        if (sentence.toLowerCase().includes('anti-corruption') || sentence.toLowerCase().includes('shareholder rights')) {
          return `<antiCorruption>${sentence}</antiCorruption>`;
        }
    
        if (sentence.toLowerCase().includes('transparency') || sentence.toLowerCase().includes('risk management')) {
          return `<transparency>${sentence}</transparency>`;
        }
    
        if (sentence.toLowerCase().includes('audit committee') || sentence.toLowerCase().includes('internal controls')) {
          return `<auditCommittee>${sentence}</auditCommittee>`;
        }
    
        if (sentence.toLowerCase().includes('accountability') || sentence.toLowerCase().includes('board independence')) {
          return `<accountability>${sentence}</accountability>`;
        }
    
        if (sentence.toLowerCase().includes('ethical conduct') || sentence.toLowerCase().includes('corporate ethics')) {
          return `<ethicalConduct>${sentence}</ethicalConduct>`;
        }
      }
    
      // General Disclosure-related keywords
      if (generalDisclosureKeywords.some(keyword => sentence.toLowerCase().includes(keyword))) {
        if (sentence.toLowerCase().includes('financial disclosures') || sentence.toLowerCase().includes('risk disclosures')) {
          return `<financialDisclosure>${sentence}</financialDisclosure>`;
        }
    
        if (sentence.toLowerCase().includes('business practices') || sentence.toLowerCase().includes('corporate policies')) {
          return `<businessPractices>${sentence}</businessPractices>`;
        }
    
        if (sentence.toLowerCase().includes('audit') || sentence.toLowerCase().includes('governance structure')) {
          return `<audit>${sentence}</audit>`;
        }
    
        if (sentence.toLowerCase().includes('financial performance') || sentence.toLowerCase().includes('management reporting')) {
          return `<financialPerformance>${sentence}</financialPerformance>`;
        }
    
        if (sentence.toLowerCase().includes('disclosure standards') || sentence.toLowerCase().includes('accounting policies')) {
          return `<disclosureStandards>${sentence}</disclosureStandards>`;
        }
      }
    
      // Return the sentence without any tags if no keyword match is found
      return sentence;

    // const xbrlTags = {
    //   // Environmental Tags
    //   'carbon footprint': '<carbonFootprint>',
    //   'carbon emissions': '<carbonEmissions>',
    //   'energy consumption': '<energyConsumption>',
    //   'renewable energy': '<renewableEnergy>',
    //   'climate change': '<climateChange>',
    //   'water usage': '<waterUsage>',
    //   'resource conservation': '<resourceConservation>',
    //   'sustainability': '<sustainability>',
    //   'greenhouse gas emissions': '<greenhouseGasEmissions>',
    //   'energy efficiency': '<energyEfficiency>',
    //   'emissions reduction': '<emissionsReduction>',
    //   'eco-friendly': '<ecoFriendly>',
    //   'clean energy': '<cleanEnergy>',
    //   'biodiversity': '<biodiversity>',
    //   'green technology': '<greenTechnology>',
    //   'carbon neutral': '<carbonNeutral>',
    //   'waste management': '<wasteManagement>',
    //   'pollution control': '<pollutionControl>',
    //   'climate adaptation': '<climateAdaptation>',
    //   'environmental protection': '<environmentalProtection>',
    //   'carbon credits': '<carbonCredits>',
    //   'environmental impact': '<environmentalImpact>',
    //   'carbon offset': '<carbonOffset>',
    //   'sustainable energy': '<sustainableEnergy>',
    //   'water conservation': '<waterConservation>',
    //   'energy conservation': '<energyConservation>',
    //   'zero emissions': '<zeroEmissions>',
    //   'recycling': '<recycling>',
    //   'green initiatives': '<greenInitiatives>',
      
    //   // Social Tags
    //   'community development': '<communityDevelopment>',
    //   'employee welfare': '<employeeWelfare>',
    //   'diversity and inclusion': '<diversityInclusion>',
    //   'social impact': '<socialImpact>',
    //   'labor rights': '<laborRights>',
    //   'human rights': '<humanRights>',
    //   'philanthropy': '<philanthropy>',
    //   'employee satisfaction': '<employeeSatisfaction>',
    //   'workplace diversity': '<workplaceDiversity>',
    //   'gender equality': '<genderEquality>',
    //   'social responsibility': '<socialResponsibility>',
    //   'inclusive policies': '<inclusivePolicies>',
    //   'health and safety': '<healthAndSafety>',
    //   'fair wages': '<fairWages>',
    //   'child labor': '<childLabor>',
    //   'forced labor': '<forcedLabor>',
    //   'work-life balance': '<workLifeBalance>',
    //   'equal opportunity': '<equalOpportunity>',
    //   'education and training': '<educationAndTraining>',
    //   'corporate social responsibility (CSR)': '<corporateSocialResponsibility>',
    //   'volunteering': '<volunteering>',
    //   'human capital development': '<humanCapitalDevelopment>',
    //   'social equity': '<socialEquity>',
    //   'living wages': '<livingWages>',
    //   'disability inclusion': '<disabilityInclusion>',
    //   'anti-discrimination': '<antiDiscrimination>',
    //   'employee empowerment': '<employeeEmpowerment>',
    //   'workplace harassment': '<workplaceHarassment>',
    //   'fair treatment': '<fairTreatment>',
    //   'community engagement': '<communityEngagement>',
    
    //   // Governance Tags
    //   'corporate governance': '<corporateGovernance>',
    //   'board diversity': '<boardDiversity>',
    //   'executive compensation': '<executiveCompensation>',
    //   'compliance': '<compliance>',
    //   'anti-corruption': '<antiCorruption>',
    //   'shareholder rights': '<shareholderRights>',
    //   'transparency': '<transparency>',
    //   'risk management': '<riskManagement>',
    //   'audit committee': '<auditCommittee>',
    //   'internal controls': '<internalControls>',
    //   'accountability': '<accountability>',
    //   'board independence': '<boardIndependence>',
    //   'board structure': '<boardStructure>',
    //   'ethical conduct': '<ethicalConduct>',
    //   'corporate ethics': '<corporateEthics>',
    //   'regulatory compliance': '<regulatoryCompliance>',
    //   'stakeholder engagement': '<stakeholderEngagement>',
    //   'whistleblowing': '<whistleblowing>',
    //   'anti-money laundering (AML)': '<antiMoneyLaundering>',
    //   'anti-bribery': '<antiBribery>',
    //   'financial oversight': '<financialOversight>',
    //   'performance-based compensation': '<performanceBasedCompensation>',
    //   'conflict of interest': '<conflictOfInterest>',
    //   'risk assessment': '<riskAssessment>',
    //   'stakeholder governance': '<stakeholderGovernance>',
    //   'company policies': '<companyPolicies>',
    //   'business ethics': '<businessEthics>',
    //   'integrity': '<integrity>',
    //   'executive pay': '<executivePay>',
    //   'ownership structure': '<ownershipStructure>',
    //   'corporate responsibility': '<corporateResponsibility>',
    //   'board effectiveness': '<boardEffectiveness>',
    
    //   // General Disclosure Tags
    //   'financial disclosures': '<financialDisclosures>',
    //   'risk disclosures': '<riskDisclosures>',
    //   'business practices': '<businessPractices>',
    //   'corporate policies': '<corporatePolicies>',
    //   'audit': '<audit>',
    //   'governance structure': '<governanceStructure>',
    //   'financial performance': '<financialPerformance>',
    //   'management reporting': '<managementReporting>',
    //   'disclosure standards': '<disclosureStandards>',
    //   'accounting policies': '<accountingPolicies>',
    //   'tax disclosure': '<taxDisclosure>',
    //   'legal disclosures': '<legalDisclosures>',
    //   'annual report': '<annualReport>',
    //   'risk management disclosures': '<riskManagementDisclosures>',
    //   'management’s discussion and analysis (MD&A)': '<MDADisclosure>',
    //   'financial reporting': '<financialReporting>',
    //   'SEC filings': '<secFilings>',
    //   'financial statements': '<financialStatements>',
    //   'quarterly earnings': '<quarterlyEarnings>',
    //   'audit report': '<auditReport>',
    //   'external audit': '<externalAudit>',
    //   'internal audit': '<internalAudit>',
    //   'accounting procedures': '<accountingProcedures>',
    //   'audit opinion': '<auditOpinion>',
    //   'management report': '<managementReport>',
    //   'investment strategy': '<investmentStrategy>',
    //   'disclosure framework': '<disclosureFramework>',
    //   'business strategy': '<businessStrategy>',
    //   'corporate reporting': '<corporateReporting>',
    //   'compliance statement': '<complianceStatement>',
    //   'economic impact': '<economicImpact>',
    //   'legal compliance': '<legalCompliance>',
    //   'disclosure practices': '<disclosurePractices>',
    //   'government regulations': '<governmentRegulations>'
    // };
    // this.tooltipMatch=sentence;
    // for (const [keyword, tag] of Object.entries(xbrlTags)) {
    //   const regex = new RegExp(`\\b${keyword}\\b`, 'gi'); 
    //   // sentence = sentence.replace(regex, tag);
    //   sentence = sentence.replace(regex, (match) => {
    //    console.log(match)
    //     // this.tooltipMatch=match;
    //     return `
    //               ${match}`;
    //   });
    // }
    // return sentence;
    
  }
  containsXBRLTag(sentence: string): boolean {
    const xbrlTags = {
      // Environmental Tags
      'carbon footprint': '<carbonFootprint>',
      'carbon emissions': '<carbonEmissions>',
      'energy consumption': '<energyConsumption>',
      'renewable energy': '<renewableEnergy>',
      'climate change': '<climateChange>',
      'water usage': '<waterUsage>',
      'resource conservation': '<resourceConservation>',
      'sustainability': '<sustainability>',
      'greenhouse gas emissions': '<greenhouseGasEmissions>',
      'energy efficiency': '<energyEfficiency>',
      'emissions reduction': '<emissionsReduction>',
      'eco-friendly': '<ecoFriendly>',
      'clean energy': '<cleanEnergy>',
      'biodiversity': '<biodiversity>',
      'green technology': '<greenTechnology>',
      'carbon neutral': '<carbonNeutral>',
      'waste management': '<wasteManagement>',
      'pollution control': '<pollutionControl>',
      'climate adaptation': '<climateAdaptation>',
      'environmental protection': '<environmentalProtection>',
      'carbon credits': '<carbonCredits>',
      'environmental impact': '<environmentalImpact>',
      'carbon offset': '<carbonOffset>',
      'sustainable energy': '<sustainableEnergy>',
      'water conservation': '<waterConservation>',
      'energy conservation': '<energyConservation>',
      'zero emissions': '<zeroEmissions>',
      'recycling': '<recycling>',
      'green initiatives': '<greenInitiatives>',
      
      // Social Tags
      'community development': '<communityDevelopment>',
      'employee welfare': '<employeeWelfare>',
      'diversity and inclusion': '<diversityInclusion>',
      'social impact': '<socialImpact>',
      'labor rights': '<laborRights>',
      'human rights': '<humanRights>',
      'philanthropy': '<philanthropy>',
      'employee satisfaction': '<employeeSatisfaction>',
      'workplace diversity': '<workplaceDiversity>',
      'gender equality': '<genderEquality>',
      'social responsibility': '<socialResponsibility>',
      'inclusive policies': '<inclusivePolicies>',
      'health and safety': '<healthAndSafety>',
      'fair wages': '<fairWages>',
      'child labor': '<childLabor>',
      'forced labor': '<forcedLabor>',
      'work-life balance': '<workLifeBalance>',
      'equal opportunity': '<equalOpportunity>',
      'education and training': '<educationAndTraining>',
      'corporate social responsibility (CSR)': '<corporateSocialResponsibility>',
      'volunteering': '<volunteering>',
      'human capital development': '<humanCapitalDevelopment>',
      'social equity': '<socialEquity>',
      'living wages': '<livingWages>',
      'disability inclusion': '<disabilityInclusion>',
      'anti-discrimination': '<antiDiscrimination>',
      'employee empowerment': '<employeeEmpowerment>',
      'workplace harassment': '<workplaceHarassment>',
      'fair treatment': '<fairTreatment>',
      'community engagement': '<communityEngagement>',
    
      // Governance Tags
      'corporate governance': '<corporateGovernance>',
      'board diversity': '<boardDiversity>',
      'executive compensation': '<executiveCompensation>',
      'compliance': '<compliance>',
      'anti-corruption': '<antiCorruption>',
      'shareholder rights': '<shareholderRights>',
      'transparency': '<transparency>',
      'risk management': '<riskManagement>',
      'audit committee': '<auditCommittee>',
      'internal controls': '<internalControls>',
      'accountability': '<accountability>',
      'board independence': '<boardIndependence>',
      'board structure': '<boardStructure>',
      'ethical conduct': '<ethicalConduct>',
      'corporate ethics': '<corporateEthics>',
      'regulatory compliance': '<regulatoryCompliance>',
      'stakeholder engagement': '<stakeholderEngagement>',
      'whistleblowing': '<whistleblowing>',
      'anti-money laundering (AML)': '<antiMoneyLaundering>',
      'anti-bribery': '<antiBribery>',
      'financial oversight': '<financialOversight>',
      'performance-based compensation': '<performanceBasedCompensation>',
      'conflict of interest': '<conflictOfInterest>',
      'risk assessment': '<riskAssessment>',
      'stakeholder governance': '<stakeholderGovernance>',
      'company policies': '<companyPolicies>',
      'business ethics': '<businessEthics>',
      'integrity': '<integrity>',
      'executive pay': '<executivePay>',
      'ownership structure': '<ownershipStructure>',
      'corporate responsibility': '<corporateResponsibility>',
      'board effectiveness': '<boardEffectiveness>',
    
      // General Disclosure Tags
      'financial disclosures': '<financialDisclosures>',
      'risk disclosures': '<riskDisclosures>',
      'business practices': '<businessPractices>',
      'corporate policies': '<corporatePolicies>',
      'audit': '<audit>',
      'governance structure': '<governanceStructure>',
      'financial performance': '<financialPerformance>',
      'management reporting': '<managementReporting>',
      'disclosure standards': '<disclosureStandards>',
      'accounting policies': '<accountingPolicies>',
      'tax disclosure': '<taxDisclosure>',
      'legal disclosures': '<legalDisclosures>',
      'annual report': '<annualReport>',
      'risk management disclosures': '<riskManagementDisclosures>',
      'management’s discussion and analysis (MD&A)': '<MDADisclosure>',
      'financial reporting': '<financialReporting>',
      'SEC filings': '<secFilings>',
      'financial statements': '<financialStatements>',
      'quarterly earnings': '<quarterlyEarnings>',
      'audit report': '<auditReport>',
      'external audit': '<externalAudit>',
      'internal audit': '<internalAudit>',
      'accounting procedures': '<accountingProcedures>',
      'audit opinion': '<auditOpinion>',
      'management report': '<managementReport>',
      'investment strategy': '<investmentStrategy>',
      'disclosure framework': '<disclosureFramework>',
      'business strategy': '<businessStrategy>',
      'corporate reporting': '<corporateReporting>',
      'compliance statement': '<complianceStatement>',
      'economic impact': '<economicImpact>',
      'legal compliance': '<legalCompliance>',
      'disclosure practices': '<disclosurePractices>',
      'government regulations': '<governmentRegulations>'
    };
    return Object.keys(xbrlTags).some(tag => sentence.toLowerCase().includes(tag.toLowerCase()));
  }

  getSafeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }


  onMouseOver(contentIndex: number, sentenceIndex: number): void {
    this.hoveredIndices[contentIndex] = sentenceIndex;
  }

  onMouseLeave(contentIndex: number): void {
    this.hoveredIndices[contentIndex] = null;
  }

  showConceptDialog(content: any) {
    // console.log(content);
    const dialogRef = this.dialog.open(TemplateTaggingReportConceptFactDialogDialogComponent, {
      width: '1200px',
      height: '600px',
      disableClose: true,
      data: {
        type: 'Concept',
        fact: content
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // console.log(result)
    })
  }

  addTopicToSection(topic: any, sentence: any): void {
    this.toggleSearchFact()
    const index = topic.dataPointId //this.templateData.findIndex(item => item.topicId === topic.topicId );
    // console.log('index', index)
    if (index !== -1) {
      this.currentIndex = index;
      this.currentContent = sentence;
    }
    const referencesSectionName = 'References';
    const sectionName = 'Sections';
    if (referencesSectionName === 'References') {
      const fullStopCount = (topic.value.match(/\./g) || []).length;
      // console.log('Number of full stops in mainContent:', fullStopCount);

      let section = this.filterSections.find(s => s.name === referencesSectionName);
      // console.log('section', section)
      const reference = {
        name: 'ESRS',
        number: 'ESRS 2',
        paragraph: topic.paragraph,
        entity: topic.dataPointId
      };
      if (section && !section.list.some(item => item.name === topic.name)) {
        section.list = []
        section.list.push(reference);
      }

    }
    if (sectionName === 'Sections') {
      // console.log(topic)
      const section = this.filterSections.find(s => s.name === sectionName);
      // console.log(section)

      if (section && !section.list.some(item => item.name === topic.name)) {
        section.list = []
        section.list.push(topic);
      }

      // console.log(' t?his.filterSections', this.filterSections)
    }
    // console.log('this.filterSections', this.filterSections);
    this.numberInMainContent(topic);
  }
  addTopicChart(topic: any, sentense: any) {
    
    const numbers = this.extractNumbers(topic.value);
    // console.log('Extracted numbers:', numbers);
    this.showBarGraph(topic);

  }
  extractNumbers(mainContent: any) {
    // console.log(mainContent)
    const matches = mainContent.match(/\d+/g);
    return matches ? matches.map(Number) : [];
  }
  numberInMainContent(topic: any) {
    const mainContentNumber = this.hasNumbers(topic);
    return mainContentNumber;

  }
  hasNumbers(mainContent: string): boolean {
    return /\d/.test(mainContent);
  }

  showBarGraph(number: any) {
    console.log(number);
    const dialogRef = this.dialog.open(TemplateTaggingReportConceptFactDialogDialogComponent, {
      width: '1200px',
      height: '600px',
      disableClose: true,
      data: {
        type: 'numberOnMainContent',
        fact: number,
        currentSentence: this.currentContent
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // console.log(result);
    })
  }


  extractNumber(content: string): string | null {
    const regex = /(\d+(\.\d+)?)/g;
    const matches = content.match(regex);

    if (matches) {
      const decimalNumbers = matches.map(num => {
        const decimalValue = (parseFloat(num) / 100).toFixed(2);
        return decimalValue.startsWith('0.') ? decimalValue : '.' + decimalValue.split('.')[1];
      });
      return decimalNumbers.join(', ');
    }
    return null;
  }

  getDisplayContent(content: any): { number: string | null, hundredths: string | null, text: string, digitsInHundredths: number } {
    const extractedNumber = this.extractNumber(this.currentContent || '');
    let hundredths: string | null = null;
    let digitsInHundredths: number = 0;

    if (extractedNumber) {
      const decimalValue = parseFloat(extractedNumber);
      if (!isNaN(decimalValue)) {
        const hundredthsValue = Math.floor(decimalValue * 100);
        hundredths = `${hundredthsValue} (hundredths)`;
        const decimalString = decimalValue.toString();
        const decimalPart = decimalString.split('.')[1];
        if (decimalPart) {
          digitsInHundredths = decimalPart.length;
        }
      }
    }

    const text = (this.currentContent || content);

    return { number: extractedNumber, hundredths: hundredths, text: text, digitsInHundredths: digitsInHundredths };
  }

  showHideChart() {
    // console.log('Original sentence:',this.currentContent );

    const percentRegex: any = /(\d+)%/g;
    const yearRegex: any = /\b(\d{4})\b/g;
    const productRegex: any = /Product (\w+)/g;

    const percentages: any[] = [];
    const years: any[] = [];
    const products: any[] = [];
    let percentMatch;
    while ((percentMatch = percentRegex.exec(this.currentContent)) !== null) {
      percentages.push(Number(percentMatch[1]));
    }

    let yearMatch;
    while ((yearMatch = yearRegex.exec(this.currentContent)) !== null) {
      years.push(yearMatch[1]);
    }

    let productMatch;
    while ((productMatch = productRegex.exec(this.currentContent)) !== null) {
      products.push(productMatch[1]);
    }
    if (percentages.length === 3 && products.length === 3) {
      return true;
    } else if (percentages.length === 2 && years.length === 2) {
      return true
    } else if (percentages.length === 1) {
      return true
    }
    else if (percentages.length === 2 && years.length === 1) {

      return true
    }
    else if (percentages.length === 2 && years.length === 0) {
      return true
    }

    else {
      return false;
    }

  }
  scrollToTopicCategory(categoryId: string, dataPointId: string) {
    // console.log('Category ID:', categoryId);
    // console.log('Data Point ID:', dataPointId);
    const targetCategoryElement = document.getElementById(categoryId);

    if (targetCategoryElement) {
      targetCategoryElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.selectedTopicId = null;
    } else {
      // console.log('Category element not found with ID', categoryId);
    }

    if (dataPointId) {
      const targetDataPointElement = document.getElementById(dataPointId);
      if (targetDataPointElement) {
        targetDataPointElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.selectedTopicId = dataPointId;
      } else {
        // console.log('Data point element not found with ID', dataPointId);
      }
    }

    if (this.highlightTimeout) {
      clearTimeout(this.highlightTimeout);
    }
    this.highlightTimeout = setTimeout(() => {
      this.selectedTopicId = null;
    }, 1000);
  }

  getFirstDataPointId(category: any): string {
    if (category && category.topics && category.topics.length > 0) {
      const firstTopic = category.topics[0];
      const firstDataPointGroup = firstTopic.dataPointGroups[0];
      if (firstDataPointGroup && firstDataPointGroup.dataPoints.length > 0) {
        return firstDataPointGroup.dataPoints[0].dataPointId;
      }
    }
    return '';
  }
  capitalizeWords(categoryName: string): string {
    if (!categoryName) return categoryName;
    return categoryName
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  }



  scrollToDataPointGrpName(dataPointGrpId: any) {
    // console.log(dataPointGrpId)
    const targetCategoryElement = document.getElementById(dataPointGrpId);
    // console.log(targetCategoryElement?.id)

    if (targetCategoryElement) {
      targetCategoryElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.togglePanel(dataPointGrpId)
    } else {
      // console.log('Category element not found with ID', dataPointGrpId);
    }
  }

  togglePanel(panelId: string) {
    if (this.currentlyExpandedPanel && this.currentlyExpandedPanel !== panelId) {
      this.panelStates[this.currentlyExpandedPanel] = false;  
    }
    this.panelStates[panelId] = !this.panelStates[panelId];
    this.currentlyExpandedPanel = this.panelStates[panelId] ? panelId : null;
  }



  downloadXbrl(): void {
    this.downloadXhtmlFile()
    
  }

  downloadXhtmlFile(): void {
    
    const xhtmlContent = this.createXhtmlContent();
    this.downloadFile(xhtmlContent);
  }

   createXhtmlContent(): string {
    const iframeUrl = 'https://survey-staging.sustain-suite.com/template-tagging-report';
    return `
      <!DOCTYPE html>
      <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
          <meta charset="UTF-8" />
          <title>Inline Viewer - Report</title>
        </head>
        
        <body>
          <div>

            <iframe id="myIframe" src="${iframeUrl}" width="100%" height="920px" style="border:none;outline:none"></iframe>
          </div>
          
        </body>
      </html>
    `;
  }

  private downloadFile(content: string): void {
    const blob = new Blob([content], { type: 'application/xhtml+xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'report.xhtml'; 
    link.click();
  }

 
 
  
}



