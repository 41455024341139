import { Routes } from '@angular/router';
import { TemplateViewerComponent } from './template-viewer/template-viewer.component';
import { TemplateQuestionAnswerComponent } from './question-answer/question-answer.component';
import { TaggingReportComponent } from './tagging-report/tagging-report.component';
import { TemplateTaggingReportComponent } from './template-tagging-report/template-tagging-report.component';
import { TemplateTaggingDataPointComponent } from './template-tagging-data-point/template-tagging-data-point.component';

export const routes: Routes = [
  { path: '', redirectTo: '/view-template', pathMatch: 'full' },
  { path: 'view-template', component: TemplateViewerComponent },
  { path: 'template-question-answer', component: TemplateQuestionAnswerComponent },
  // { path: 'tagging-report', component: TaggingReportComponent },
  // { path: 'template-tagging-report', component: TemplateTaggingReportComponent }, //this one now running in stage,prod
  // { path: 'template-tagging-data-point', component: TemplateTaggingDataPointComponent }  //thjis is new for data point
  { path: 'template-tagging-report', component: TemplateTaggingDataPointComponent }  //thjis is new for data point

];
