import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CapitalizeFirstPipeModule } from '../pipes/capitaliza.module';
import { RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedService } from '../shared.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import * as echarts from 'echarts';
import { EchartsxModule } from 'echarts-for-angular';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { TooltipComponent, GridComponent, LegendComponent, ToolboxComponent, TitleComponent } from "echarts/components";
import { LineChart, BarChart, PieChart, RadarChart } from 'echarts/charts';


@Component({
  selector: 'app-template-tagging-report-concept-fact-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule, CapitalizeFirstPipeModule,CommonModule, RouterOutlet,MatIconModule,  MatTooltipModule, FontAwesomeModule,MatButtonModule,MatCheckboxModule,FormsModule,
    EchartsxModule, NgxEchartsDirective,
  ],
  providers:[DatePipe],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './template-tagging-report-concept-fact-dialog.component.html',
  styleUrls: ['./template-tagging-report-concept-fact-dialog.component.scss','../common.scss']
})
export class TemplateTaggingReportConceptFactDialogDialogComponent implements OnInit,AfterViewInit {
  
    textBinding:boolean=false;
    isEntityToggled: boolean = false;
    isUnitToggled: boolean = false;
    isPeriodToggled: boolean = false;
    sentence: any = '';
    numbers: any[] = [];
    labels: any[] = [];
    chartType: any = '';
    chartData: any = [];




  constructor(@Inject(MAT_DIALOG_DATA) public dialogData:any,private datePipe:DatePipe,
    private dialogRef: MatDialogRef<TemplateTaggingReportConceptFactDialogDialogComponent>,
    private cd: ChangeDetectorRef, public sharedService: SharedService) {
    console.log(dialogData);
    this.onAddToTopic(this.dialogData.currentSentence)
    
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void {
  //  this.barChartInIt()
  // this.chartBtnActionPeriod(false)
  this.renderChart();

  }
  containsHtml(value: string): boolean {
    const regex = /(<([^>]+)>)/gi;
    return regex.test(value);
  }

  barChartInIt(){
    if (this.dialogData && this.dialogData.type === 'numberOnMainContent') {
      const numbers = this.extractNumbers(this.dialogData.fact.value); 
      // console.log(numbers)
      this.showBarGraph(numbers,this.dialogData.fact);
    }
  }
  extractNumbers(mainContent: any) {
    // console.log(mainContent)
    const matches = mainContent.match(/\d+/g);
    return matches ? matches.map(Number) : [];
  }

  showBarGraph(numbers: number[],factValue:any) {
    // console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      // console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
    
    const option = {
      // title: {
      //   text: 'Numbers from Main Content'
      // },
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.dataPointId}`;
        },
      },
      legend: {
        data: [factValue.dataPointId], 
        orient: 'horizontal', // Keep it horizontal
            top: '0%', // Position legend at the top
            left: 'center', // Center the legend horizontally
            padding: [5, 10], // Optional padding for better aesthetics
      },
      xAxis: {
        type: 'category',
        data: numbers.map((_, index) => `${this.datePipe.transform( factValue.startDate,'d MMM, y') +' to ' + this.datePipe.transform( factValue.endDate,'d MMM, y')}`),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: factValue.dataPointId,
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }

  toggleEntity() {
    this.isEntityToggled = !this.isEntityToggled;
  }

  toggleUnit() {
    this.isUnitToggled = !this.isUnitToggled;
  }

  togglePeriod() {
    this.isPeriodToggled = !this.isPeriodToggled;
  }
  chartBtnActionPeriod(toggle:boolean){
    // console.log(toggle)
    if ( toggle===true) {
      const numbers = this.extractNumbers(this.dialogData.fact.value); 
      this.showBarGraphPeriod(numbers,this.dialogData.fact);
    }
    else{
      this.barChartInIt()
    }

  }

  showBarGraphPeriod(numbers: number[],factValue:any) {
    // console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      // console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
    const startDate = this.datePipe.transform(factValue.startDate, 'd MMM, y');
    const endDate = this.datePipe.transform(factValue.endDate, 'd MMM, y');
        const legendTitle = `${factValue.dataPointId} (${startDate} to ${endDate})`;
    const option = {
      
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.dataPointId}`;
        },
      },
      legend: {
        data: [legendTitle], 
        orient: 'horizontal', 
            top: '0%',
            left: 'center',
            padding: [5, 10], 
      },
      xAxis: {
        type: 'category',
        data: numbers.map(() => legendTitle),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: legendTitle, 
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }
  chartBtnActionUnit(toggle:boolean){
    // console.log(toggle)
    if ( toggle===true) {
      const numbers = this.extractNumbers(this.dialogData.fact.value); 
      this.barChartInItUnit(numbers,this.dialogData.fact);
    }
    else{
      const numbers = this.extractNumbers(this.dialogData.fact.value); 
      this.showBarGraphUnit(numbers,this.dialogData.fact)
    }
  }

  showBarGraphUnit(numbers: number[],factValue:any) {
    // console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      // console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
        const legendTitle = `${factValue.dataPointId}`;
    const option = {
      
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.dataPointId}`;
        },
      },
      legend: {
        data: [legendTitle], 
        orient: 'horizontal', 
            top: '0%',
            left: 'center',
            padding: [5, 10], 
      },
      xAxis: {
        type: 'category',
        data: numbers.map((_, index) => `${this.datePipe.transform( factValue.startDate,'d MMM, y') +' to ' + this.datePipe.transform( factValue.endDate,'d MMM, y')}`),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: legendTitle, 
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }
  barChartInItUnit(numbers: number[],factValue:any) {
    // console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      // console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
        const legendTitle = `xbrl:pure`;
    const option = {
      
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.dataPointId}`;
        },
      },
      legend: {
        data: [legendTitle], 
        orient: 'horizontal', 
            top: '0%',
            left: 'center',
            padding: [5, 10], 
      },
      xAxis: {
        type: 'category',
        data: numbers.map((_, index) => `${this.datePipe.transform( factValue.startDate,'d MMM, y') +' to ' + this.datePipe.transform( factValue.endDate,'d MMM, y')}`),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: legendTitle, 
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }
  chartBtnActionEntity(toggle:boolean){
    // console.log(this.dialogData.fact)
    if ( toggle===true) {
      const numbers = this.extractNumbers(this.dialogData.fact.value); 
      this.barChartInItEntity(numbers,this.dialogData.fact);
    }
    else{
      const numbers = this.extractNumbers(this.dialogData.fact.value); 
      this.barChartInItEntity(numbers,this.dialogData.fact)
    }
  }

 
  barChartInItEntity(numbers: number[],factValue:any) {
    // console.log(numbers)
    const chartDom = document.getElementById('bar-chart');
    if (!chartDom) {
      // console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);
        const legendTitle = `${factValue.dataPointId}`;
    const option = {
      
      tooltip: {
        trigger: 'item', 
        formatter: (params:any) => {
          const index = params.dataIndex; 
          const value = params.value; 
          return `${params.name}<br/>Entity: ${factValue.dataPointId}`;
        },
      },
      legend: {
        data: [legendTitle], 
        orient: 'horizontal', 
            top: '0%',
            left: 'center',
            padding: [5, 10], 
      },
      xAxis: {
        type: 'category',
        data: numbers.map((_, index) => `${this.datePipe.transform( factValue.startDate,'d MMM, y') +' to ' + this.datePipe.transform( factValue.endDate,'d MMM, y')}`),
        color: '#333',
        fontSize: 14 
      },
      yAxis: {
        type: 'value',
        name: 'XBRL: Pure', 
        nameLocation: 'middle',
        nameGap: 30, 
        nameTextStyle: {
          color: '#333',
          fontSize: 14 
        },
        splitLine: {
          show: true, 
          lineStyle: {
            color: '#ccc', 
            width: 2, 
            type: 'solid' 
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7e7e7e', 
            width: 1
          }
        }
      },
      series: [{
        name: legendTitle, 
        type: 'bar',
        data: numbers,
        itemStyle: {
          color: '#66ca00' 
        }
      }]
    };

    myChart.setOption(option);
  }


  onAddToTopic(sentence: string): void {
    // console.log('Original sentence:', sentence);

    const percentRegex = /(\d+)%/g;
    const yearRegex = /\b(\d{4})\b/g;
    const productRegex = /Product (\w+)/g;

    const percentages: number[] = [];
    const years: string[] = [];
    const products: string[] = [];
    let percentMatch;
    while ((percentMatch = percentRegex.exec(sentence)) !== null) {
        percentages.push(Number(percentMatch[1])); 
    }

    let yearMatch;
    while ((yearMatch = yearRegex.exec(sentence)) !== null) {
        years.push(yearMatch[1]);
    }

    let productMatch;
    while ((productMatch = productRegex.exec(sentence)) !== null) {
        products.push(productMatch[1]);
    }
    if (percentages.length === 3 && products.length === 3) {
        this.numbers = percentages;
        this.labels = products;
        this.chartType = 'pie'; 
    } else if (percentages.length === 2 && years.length === 2) {
        this.numbers = percentages;
        this.labels = years;
        this.chartType = 'bar';  
    } else if (percentages.length === 1) {
        this.numbers = percentages;
        this.labels = years;
        this.chartType = 'bar'; 
    }
    else if (percentages.length === 2 && years.length === 1) {
     
      this.numbers = percentages;  
      this.labels = years;  
      this.chartType = 'line';  
    }
    else if (percentages.length === 2 && years.length === 0) {
      this.numbers = percentages;
      this.labels = this.numbers; 
      this.chartType = 'bar'; 
    }
    
    else {
        // console.error('Could not determine chart type from the sentence:', sentence);
    }

    // console.log('Extracted Numbers:', this.numbers);
    // console.log('Chart Type:', this.chartType);

    setTimeout(() => {
      this.renderChart();  
    }, 0);
  }


  renderChart(): void {
    const chartDom = document.getElementById('bar-chart_render');
    if (!chartDom) {
      // console.error('Chart DOM element not found!');
      return;
    }

    const myChart = echarts.init(chartDom);

    let option: any;
    switch (this.chartType) {
      case 'bar':
        option = {
          tooltip: {
            trigger: 'item',
            formatter: (params: any) => `${params.name}: ${params.value}%`
          },
          xAxis: {
            type: 'category',
            data: this.labels,
          },
          yAxis: {
            type: 'value',
            name: '%'
          },
          series: [{
            data: this.numbers,
            type: 'bar',
            itemStyle: { color: '#66ca00' }
          }]
        };
        break;
      case 'pie':
        option = {
          tooltip: {
            trigger: 'item',
            formatter: (params: any) => `${params.name}: ${params.value}%`
          },
          series: [{
            name: 'Market Share',
            type: 'pie',
            radius: '50%',
            data: this.labels.map((label, index) => ({
              name: label,
              value: this.numbers[index]
            })),
            itemStyle: { color: '#66ca00' }
          }]
        };
        break;
      case 'line':
        option = {
          tooltip: {
            trigger: 'item',
            formatter: (params: any) => `${params.name}: ${params.value}%`
          },
          xAxis: {
            type: 'category',
            data: this.labels
          },
          yAxis: {
            type: 'value',
            name: '%'
          },
          series: [{
            data: this.numbers,
            type: 'line',
            itemStyle: { color: '#66ca00' },
            lineStyle: { width: 2 }
          }]
        };
        break;
      default:
        // console.error('Unknown chart type');
        return;
    }

    myChart.setOption(option);
  }



}




