<section #htmlContentContainer>
<ix:tag xmlns:ix="http://www.xbrl.org/2008/inlineXBRL" xmlns:esrs="http://example.com/esrs">
    <header class="card custom_card" id="financial-report" >
        <div class="d-flex justify_content_between align_item_baseline mgn-up-10">
            <div class="d-flex justify_content_start align_item_center gap_10">
                <div class="d-flex justify_content_start gap_10">
                    <div class="xbrl_element_INline_viewer">
                        <ix:nonNumeric>Inline Viewer</ix:nonNumeric>
                    </div>
                    <div class="xbrl_element">
                        <ix:nonNumeric>Report</ix:nonNumeric>
                    </div>
                </div>
            </div>
            <div class="d-flex  align_item_baseline">
                <div class="d-flex gap_5">
                    <div class="xbrl_element_INline_viewer">
                        <ix:nonNumeric>Highlight</ix:nonNumeric>
                    </div>
                    <div> <mat-icon (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false" class="fs_14">
                            help_outline
                        </mat-icon>
                        <div *ngIf="showTooltip" class="custom-tooltip">
                            <div class="tooltip-icon">
                                <div class="tooltip_icon_esrs"></div>
                            </div>
                            <span>esrs</span>
                        </div>
                    </div>
                </div>


                <div class="d-flex justify_content_end gap_10 align_item_center">
                    <div><input type="checkbox" (change)="onCheckboxChange($event)" [(ngModel)]="isChecked" /><span
                            class="xbrl_element mgn-lt-5">
                            <ix:nonNumeric>XBRL Elements</ix:nonNumeric>
                        </span></div>
                        <button (click)="downloadXbrl()" mat-button class="xbrl_element_INline_viewer" id="download-btn">Download as XBRL</button>
                </div>

            </div>
        </div>
    </header>

    <div class="d-flex gap_20 scroll_card" id="top_template">
        <div class="zoomable-wrapper width_60 mgn-up-10 left-content" [style.transform]="zoomLevel">
            <div class="card mgn-up-10 zoomable-content card_content">
                <div>
                    <div class="text-center company_name">
                        <ix:nonNumeric>M&P Group 2024</ix:nonNumeric>
                    </div>
                    <div class="text-center mgn-up-10 company_label">
                        <ix:nonNumeric>Management and Sustainability Report</ix:nonNumeric>
                    </div>
                </div>
                <ng-container *ngIf="templateData && templateData.length > 0">
                    <div class="mgn-up-20 content" esrs:contents>Contents</div>
                    <div class="mainContentHover_custom" *ngIf="templateData && templateData.length>0">
                        <ng-container *ngFor="let category of templateData; let i = index">
                            <div class="d-flex justify_content_between topic_name_hover align_item_baseline">
                                <div class="width_100">
                                    <div class="mgn-up-10 topic-name d-flex gap_5 topic_name_hover" esrs:categoryName
                                        (click)="scrollToTopicCategory(category.id,getFirstDataPointId(category))">
                                        <div class="categoryNmae_color categoryName_wrapper">
                                            <ix:nonNumeric>{{ capitalizeWords(category['categoryName']) }}
                                            </ix:nonNumeric>
                                        </div>
                                        <div class="index_wrapper">
                                            <ix:nonNumeric>{{ category['categoryName'] }}</ix:nonNumeric>
                                        </div>
                                    </div>
                                    <div *ngIf="category.topics && category.topics.length > 0"
                                        class="mgn-lt-5 width_100 lh-2 topic_name_hover mgn-up-5">
                                        <div class="d-flex justify_content_start align_item_center"
                                            (click)="scrollToTopic(category.topics[0].id)">
                                            <ng-container
                                                *ngIf="category &&  category['categoryName'] &&  category['categoryName']==='General Disclosures'">
                                                <div class="topic-name topic-name_clr ">{{ category.topics[0].name }}
                                                </div>
                                                <div class="topic-name topic-name_clr index_wrapper">General Disclosures
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="category &&  category['categoryName'] &&  category['categoryName']==='Environment'">
                                                <div class="topic-name topic-name_clr ">{{
                                                    category.topics[0].name.slice(5,8) }}</div>
                                                <div class="topic-name topic-name_clr index_wrapper">Climate Change
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="category &&  category['categoryName'] &&  category['categoryName']==='Social'">
                                                <div class="topic-name topic-name_clr ">{{
                                                    category.topics[0].name.slice(5,8) }}</div>
                                                <div class="topic-name topic-name_clr index_wrapper">Own Workforce</div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="category &&  category['categoryName'] &&  category['categoryName']==='Governance'">
                                                <div class="topic-name topic-name_clr ">{{
                                                    category.topics[0].name.slice(5,8) }}</div>
                                                <div class="topic-name topic-name_clr index_wrapper">Business Conduct
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="topic-name" style="width:25px;font-size:12px">
                                    {{i+1 }}
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <footer class="mgn-up-20">
                        <div class="footer">
                            <ix:nonNumeric>This document has been prepared by the Sustain-Suite to illustrate the
                                outline of
                                the digital reporting with examples. It accompanies the Explanatory Note and Basis for
                                Conclusions but it is not part of the Draft ESRS Set 1 XBRL Taxonomy for consultation.
                            </ix:nonNumeric>
                            <span class="company_name_footer">
                                <ix:nonNumeric>© Sustain-Suite 2024 - https://sustain-suite.com/</ix:nonNumeric>
                            </span>
                        </div>
                    </footer>
                </ng-container>
                <ng-container *ngIf="templateData && templateData.length === 0">
                    <div class="d-flex justify_content_center mgn-up-20">
                        <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [value]="value"
                            [diameter]="50">
                        </mat-progress-spinner>
                    </div>
                    <div class="animated-text mgn-up-10">
                        <span *ngFor="let letter of animatedText; let i = index" [style.animationDelay]="i * 1 + 's'"
                            class="letter">
                            {{ letter }}
                        </span>
                    </div>
                </ng-container>
            </div>
            <!-- content end -->
            <!-- //report part chqart -->
            <ng-container *ngIf="templateData && templateData.length > 0">
                <div *ngFor="let category of templateData; let i = index" [id]="category.id" #contentRefs
                    class="card mgn-up-10">

                    <ng-container *ngIf="category.categoryName ==='General Disclosures'">
                        <div class="d-flex gap_5 align_item_center">
                            <div class="topic_name_cate">ESRS 2</div>
                            <div class="topic_name_cate">General Disclosures</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="category.categoryName ==='Environment'">
                        <div class="d-flex gap_5 align_item_center">
                            <div class="topic_name_cate">E1</div>
                            <div class="topic_name_cate">Climate Chnage</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="category.categoryName ==='Social'">
                        <div class="d-flex gap_5 align_item_center">
                            <div class="topic_name_cate">S1</div>
                            <div class="topic_name_cate">Own Workforce</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="category.categoryName ==='Governance'">
                        <div class="d-flex gap_5 align_item_center">
                            <div class="topic_name_cate">G1</div>
                            <div class="topic_name_cate">Business Conduct</div>
                        </div>
                    </ng-container>

                    <div *ngFor="let topic of category.topics" class="mgn-up-10">
                        <div class="topic_name_cate" [id]="topic.id">
                            <div class="dot dot_topic "></div>
                            <ix:nonNumeric>{{ topic.name }}</ix:nonNumeric>
                        </div>
                        <div *ngFor="let dataPointGrp of topic.dataPointGroups" class="mgn-up-10" >
                            <!-- <mat-accordion class="example-headers-align"> -->
                                <div  class="custom-panel"
                                    [id]="dataPointGrp.id" #panelContentRefs >
                                    <div class="d-flex justify_content_between align_items_start cursor-pointer" (click)="togglePanel(dataPointGrp.id)" >
                                        <div class="topic_name_cate">
                                            <div class="dot dot_topic dot_topic_drcode"></div>
                                            <ix:nonNumeric>{{ dataPointGrp.drCode}}</ix:nonNumeric>
                                            <ix:nonNumeric>{{ dataPointGrp.name }}</ix:nonNumeric>
                                        </div>
                                        <div class="expand_id_icon" [ngClass]="{collapse_icon: panelStates[dataPointGrp.id]}">{{ panelStates[dataPointGrp.id] ? '-' : '+' }}</div>
                                    </div>

                                    <div *ngIf="panelStates[dataPointGrp.id]" [@expandCollapse]="panelStates[dataPointGrp.id] ? '-' : '+'">

                                   
                                    <div *ngFor="let dataPoint of dataPointGrp.dataPoints; let dataPointIndex = index"
                                        class="card mgn-up-10  zoomable-content card_content"
                                        [id]="dataPoint.dataPointId" #contentRefs
                                        [ngClass]="{'selected-topic': selectedTopicId === dataPoint.dataPointId}">

                                        <div class="mainContentHover mainContentHover_custom">
                                            <div class="topic-name-content" esrs:contentTitle>
                                                <div class="data-point-id">
                                                    <ix:nonNumeric>{{ dataPoint.dataPointId }}</ix:nonNumeric>
                                                </div>
                                                <div>
                                                    <ix:nonNumeric>{{ dataPoint.name }}</ix:nonNumeric>
                                                </div>
                                            </div>
                                            <div>
                                                <!-- <ix:nonNumeric> -->
                                                    <div *ngIf="dataPoint && dataPoint['name']"
                                                        class="topic-name-main-content" esrs:dataPointName
                                                        [ngClass]="getHighlightClass()"
                                                        >
                                                        <span 
                                                            *ngFor="let sentence of formatSentences(dataPoint['value']); let j = index"
                                                            [ngClass]="{'hover-effect': hoveredIndices[dataPointIndex] === j}"
                                                            (mouseover)="onMouseOver(dataPointIndex, j)"
                                                            (mouseleave)="onMouseLeave(dataPointIndex)"
                                                            (click)="addTopicToSection(dataPoint, sentence)"
                                                            >
                                                            <span [innerHtml]="getSafeHtml(sentence)" [matTooltip]="containsXBRLTag(sentence)?sentence:' '"></span>
                                                        </span>
                                                        
                                                    </div>
                                                    

                                                <!-- </ix:nonNumeric> -->
                                            </div>
                                        </div>
                                        <footer class="mgn-up-20">
                                            <div class="footer">
                                                <ix:nonNumeric>This document has been prepared by the Sustain-Suite to
                                                    illustrate the
                                                    outline of the digital reporting with examples. It accompanies the
                                                    Explanatory Note and
                                                    Basis for Conclusions but it is not part of the Draft ESRS Set 1
                                                    XBRL Taxonomy for
                                                    consultation.
                                                </ix:nonNumeric>
                                                <span class="company_name_footer">
                                                    <ix:nonNumeric>© Sustain-Suite 2024 - https://sustain-suite.com/
                                                    </ix:nonNumeric>
                                                </span>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                                </div>
                            <!-- </mat-accordion> -->
                        </div>
                    </div>
                </div>

                <div class="card mgn-up-10">
                    <div class="ref_label">Disclosure requirements and incorporation by reference</div>
                    <ng-container *ngFor="let category of templateData; let i = index">
                        <div class="d-flex justify_content_between topic_name_hover align_item_baseline">
                            <div class="width_100">
                                <ng-container *ngIf="category.categoryName ==='General Disclosures'">
                                    <div class="d-flex gap_5 align_item_center"
                                        (click)="scrollToTopic(category.topics[0].id)">
                                        <div class="index_wrapper_new">ESRS 2</div>
                                        <div class="index_wrapper_gd">General Disclosures</div>
                                    </div>
                                    <div *ngFor="let topic of category.topics;let topicIndex=index"
                                        class="mgn-lt-5 width_100 lh-2 mgn-up-10">

                                        <ng-container *ngFor="let dataPointGrp of topic.dataPointGroups">
                                            <div class="topic_name_hover topic-name topic-name_clr index_wrapper gap_5 d-flex align_item_start"(click)="scrollToDataPointGrpName(dataPointGrp.id)">
                                                <div class="drCode">
                                                    <ix:nonNumeric>{{ dataPointGrp.drCode }}</ix:nonNumeric>
                                                </div>
                                                <div class="dataPointGrpName">
                                                    <ix:nonNumeric>{{ dataPointGrp.name }}</ix:nonNumeric>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="category.categoryName ==='Environment'">
                                    <div class="d-flex gap_5 align_item_center"
                                        (click)="scrollToTopic(category.topics[0].id)">
                                        <div class="index_wrapper_new">E1</div>
                                        <div class="index_wrapper_gd">Climate Chnage</div>
                                    </div>
                                    <div *ngFor="let topic of category.topics;let topicIndex=index"
                                        class="mgn-lt-5 width_100 lh-2 mgn-up-10">
                                        <ng-container *ngFor="let dataPointGrp of topic.dataPointGroups">
                                            <div class="topic_name_hover topic-name topic-name_clr index_wrapper gap_5 d-flex align_item_start"(click)="scrollToDataPointGrpName(dataPointGrp.id)">
                                                <div class="drCode">
                                                    <ix:nonNumeric>{{ dataPointGrp.drCode }}</ix:nonNumeric>
                                                </div>
                                                <div class="dataPointGrpName">
                                                    <ix:nonNumeric>{{ dataPointGrp.name }}</ix:nonNumeric>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="category.categoryName ==='Social'">
                                    <div class="d-flex gap_5 align_item_center"
                                        (click)="scrollToTopic(category.topics[0].id)">
                                        <div class="index_wrapper_new">S1</div>
                                        <div class="index_wrapper_gd">Own Workforce</div>
                                    </div>
                                    <div *ngFor="let topic of category.topics;let topicIndex=index"
                                        class="mgn-lt-5 width_100 lh-2 mgn-up-10">
                                        <ng-container *ngFor="let dataPointGrp of topic.dataPointGroups">
                                            <div class="topic_name_hover topic-name topic-name_clr index_wrapper gap_5 d-flex align_item_start"(click)="scrollToDataPointGrpName(dataPointGrp.id)">
                                                <div class="drCode">
                                                    <ix:nonNumeric>{{ dataPointGrp.drCode }}</ix:nonNumeric>
                                                </div>
                                                <div class="dataPointGrpName">
                                                    <ix:nonNumeric>{{ dataPointGrp.name }}</ix:nonNumeric>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="category.categoryName ==='Governance'">
                                    <div class="d-flex gap_5 align_item_center"
                                        (click)="scrollToTopic(category.topics[0].id)">
                                        <div class="index_wrapper_new">G1</div>
                                        <div class="index_wrapper_gd">Business Conduct</div>
                                    </div>
                                    <div *ngFor="let topic of category.topics;let topicIndex=index"
                                        class="mgn-lt-5 width_100 lh-2 mgn-up-10">

                                        <ng-container *ngFor="let dataPointGrp of topic.dataPointGroups">
                                            <div class="topic_name_hover topic-name topic-name_clr index_wrapper gap_5 d-flex align_item_start"(click)="scrollToDataPointGrpName(dataPointGrp.id)">
                                                <div class="drCode">
                                                    <ix:nonNumeric>{{ dataPointGrp.drCode }}</ix:nonNumeric>
                                                </div>
                                                <div class="dataPointGrpName">
                                                    <ix:nonNumeric>{{ dataPointGrp.name }}</ix:nonNumeric>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="topic-name" style="width:25px;font-size:12px">
                                {{i+1 }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <div class="card width_30 mgn-up-20 right-content" esrs:rightPanel
            *ngIf="templateData && templateData.length > 0">
            <div class="d-flex justify_content_between align_item_center" esrs:header>
                <div class="xbrl_element">
                    <ix:nonNumeric>{{factLabelFilter}}</ix:nonNumeric>
                </div>
                <div>
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>settings</mat-icon>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <ix:nonNumeric>English</ix:nonNumeric>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="d-flex justify_content_between icon_bg" esrs:iconSection>
                <div class="mgn-up-10 d-flex justify_content_start gap_10 align_item_center">
                    <div class="headline cursor-pointer" [ngClass]="{'active': activeIcon === 'outlineUnavailable'}"
                        [matTooltip]="'Outline'" (click)="toggleSearchOutline()">
                        <mat-icon class="icon_height">view_headline</mat-icon>
                    </div>
                    <div class="search cursor-pointer" [ngClass]="{'active': activeIcon === 'search'}"
                        [matTooltip]="'Search'" (click)="toggleSearch()">
                        <mat-icon class="icon_height">search</mat-icon>
                    </div>
                    <div class="search cursor-pointer" [ngClass]="{'active': activeIcon === 'info'}"
                        [matTooltip]="'Fact'">
                        <mat-icon class="icon_height">info</mat-icon>
                    </div>
                </div>

            </div>

            <ng-container *ngIf="showSearchInput">
                <div class="d-flex justify_content_between align_items_center mgn-up-10 gap_10 search_filter_bg">
                    <input type="search" placeholder="Search by title..." [(ngModel)]="searchQuery"
                        (input)="onSearchChange()" class="search-input" />
                    <div class="headline" [matTooltip]="'Filter'" (click)="toggleFilter()">
                        <mat-icon>filter_list</mat-icon>
                    </div>
                </div>
                <div *ngIf="showFilterOptions">
                    <div class="d-flex justify_content_between align_item_center mgn-up-10">
                        <div class="search-label">Filter</div>
                        <div class="cursor-pointer search-label" (click)="resetFilters()">Reset</div>
                    </div>
                    <!-- <div class="mgn-up-10 d-flex justify_content_between align_item_center">
                        <div class="search-label">Concept Type</div>
                        <div class="width_50">
                            <select class="mat-select">
                                <option>All</option>
                                <option>Text</option>
                            </select>
                        </div>
                    </div>
                    <div class="mgn-up-10 d-flex justify_content_between align_item_center">
                        <div class="search-label">Period</div>
                        <div class="width_50">
                            <select (change)="onDateChange($event)" class="mat-select">
                                <option value="all">All Dates</option>
                                <option *ngFor="let date of uniqueDates" [value]="date">{{ date | date:'d MMMM y' }}
                                </option>
                            </select>
                        </div>
                    </div> -->
                </div>

                <div *ngIf="searchList && searchList.length===0 " class="fact-label">
                    <div><mat-icon>search</mat-icon></div>
                    <div class="fs_25">
                        <ix:nonNumeric>Search Unavailable</ix:nonNumeric>
                    </div>
                </div>
                <div *ngIf="searchList && searchList.length >0 " class="fact-label">
                    <ng-container *ngFor="let content of searchList;let i = index">
                        <div class="mgn-up-10 search-filter_data-bg  searchTopic_bg">
                            <ng-container *ngFor="let topic of content.topics">
                                <ng-container *ngFor="let dataPointGrp of topic.dataPointGroups">
                                    <!-- <div *ngFor="let dataPoint of dataPointGrp.dataPoints; let dataPointIndex = index"
                                        [id]="dataPoint.dataPointId" #contentRefs> -->
                                        <div (click)="scrollToTopic(dataPointGrp.id)"
                                            [ngClass]="{'selected-topic_search': selectedOutlineIndexForSearch === dataPointGrp.id}"
                                            class="searchTopicName">
                                            <div class="d-flex justify_content_start gap_5 topic_dot cursor-pointer">
                                                <ix:nonNumeric>{{dataPointGrp.name}}</ix:nonNumeric>
                                            </div>
                                            <!-- <div>
                                                <mat-icon (click)="openSearchFactList(i,dataPointGrp)"
                                                    style="color: #026dce;">subdirectory_arrow_left</mat-icon>
                                            </div> -->

                                        </div>
                                    <!-- </div> -->
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="showOutlineList">
                <div *ngIf="templateData && templateData.length===0 " class="fact-label">
                    <div><mat-icon>view_headline</mat-icon></div>
                    <div class="fs_25">
                        <ix:nonNumeric>Outline Unavailable</ix:nonNumeric>
                    </div>
                </div>
                <div *ngIf="searchList && searchList.length >0 " class="fact-label">
                    <ng-container *ngFor="let content of searchList;let i = index">
                        <div class="mgn-up-10 search-filter_data-bg  searchTopic_bg">
                            <ng-container *ngFor="let topic of content.topics">
                                <ng-container *ngFor="let dataPointGrp of topic.dataPointGroups">
                                    <!-- <ng-container
                                        *ngFor="let dataPoint of dataPointGrp.dataPoints; let dataPointIndex = index"> -->
                                        <div (click)="scrollToTopic(dataPointGrp.id)"
                                            [ngClass]="{'selected-topic_search': selectedOutlineIndex === dataPointGrp.id}"
                                            class="searchTopicName">
                                            <div class="d-flex justify_content_start gap_5 topic_dot cursor-pointer">
                                                <ix:nonNumeric>{{dataPointGrp.name}}</ix:nonNumeric>
                                            </div>

                                        </div>
                                    <!-- </ng-container> -->
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="searchListShowHide">
                <div *ngIf="templateData && templateData.length === 0" class="fact-label" esrs:noFactAvailable>
                    <div><mat-icon>edit</mat-icon></div>
                    <div class="fs_25">
                        <ix:nonNumeric>No Fact Available</ix:nonNumeric>
                    </div>
                </div>
                <ng-container *ngIf="templateData && templateData.length > 0">
                    <div class="right_bg">
                        <div>
                            <ng-container *ngFor="let content of templateData; let i = index">
                                <ng-container *ngFor="let topic of content.topics">
                                    <ng-container *ngFor="let dataPointGrp of topic.dataPointGroups">
                                        <ng-container
                                            *ngFor="let dataPoint of dataPointGrp.dataPoints; let dataPointIndex = index">
                                            <div *ngIf="dataPoint.dataPointId === currentIndex" esrs:fact>
                                                <div class="disclousr_topic">
                                                    <ix:nonNumeric> Disclosure of {{dataPoint['name']}}[text block]
                                                    </ix:nonNumeric>
                                                </div>
                                                <div *ngIf="(getDisplayContent(dataPoint['name'])).number"
                                                    class="typed_axis">Quantitative metric (percentage)</div>
                                                <div class="concept" esrs:concept>
                                                    <ix:nonNumeric>Concept</ix:nonNumeric>
                                                </div>
                                                <div class="mgn-up-10">
                                                    <div class="d-flex justify_content_start gap_5 topic_dot">
                                                        <div class="dot"></div>
                                                        <div class="topic-name">
                                                            <ix:nonNumeric>{{dataPoint['name']}}</ix:nonNumeric>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="concept mgn-up-10">
                                                    <ix:nonNumeric>Dimensions</ix:nonNumeric>
                                                </div>

                                                <div *ngIf="(getDisplayContent(dataPoint['name'])).number && showHideChart()"
                                                    class="content_label mgn-bt-10  d-flex align_item_baseline gap_5">
                                                    <div class="dot_matrix"></div>
                                                    <div class="concept">Name of metric(s) [typed axis]<mat-icon
                                                            style="color: #0094ff; font-size: 18px;padding: 9px 5px;"
                                                            class="chart-icon"
                                                            (click)="addTopicChart(dataPoint,(getDisplayContent(dataPoint['name'])).number)"
                                                            matTooltip="Show graph">
                                                            bar_chart
                                                        </mat-icon></div>
                                                </div>
                                                <div *ngIf="(getDisplayContent(dataPoint['name'])).number && showHideChart()"
                                                    class="content_label mgn-bt-10 mgn-up-10 d-flex align_item_baseline gap_5">
                                                    <div class="dot_matrix"></div>
                                                    <div class="concept">MetricName</div>
                                                </div>

                                                <div class="d-flex justify_content_start gap_10 align_item_center mgn-up-10"
                                                    *ngIf="(getDisplayContent(dataPoint['name'])).text">
                                                    <div class="concept">
                                                        <ix:nonNumeric>Fact </ix:nonNumeric>
                                                    </div>
                                                    <div class="fw_400 d-flex align_item_center">
                                                        <div class="company-topic-name">
                                                            <ix:nonNumeric
                                                                [innerHTML]="(getDisplayContent(dataPoint['name'])).text ">
                                                            </ix:nonNumeric>
                                                        </div>
                                                        <mat-icon matTooltip="Show fact"
                                                            (click)="showConceptDialog(dataPoint)"
                                                            style="color: #0094ff; font-size: 18px;padding: 3px 5px;">visibility</mat-icon>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify_content_start gap_10 align_item_baseline mgn-up-10"
                                                    *ngIf="(getDisplayContent(dataPoint['name'])).number && showHideChart()">
                                                    <div class="concept">
                                                        <ix:nonNumeric>Fact Value </ix:nonNumeric>
                                                    </div>
                                                    <div class="fw_400 d-flex align_item_baseline">
                                                        <div class="company-topic-name">
                                                            <ix:nonNumeric>
                                                                {{(getDisplayContent(dataPoint['name'])).number +
                                                                'xbrli:pure'}}</ix:nonNumeric>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10"
                                                    *ngIf="(getDisplayContent(dataPoint['name'])).digitsInHundredths&& showHideChart() ">
                                                    <div class="concept">
                                                        <ix:nonNumeric>Accuracy</ix:nonNumeric>
                                                    </div>
                                                    <div class="content_label">
                                                        <ix:nonNumeric>
                                                            {{(getDisplayContent(dataPoint['name'])).digitsInHundredths
                                                            +1 + ' hundredths'}}
                                                        </ix:nonNumeric>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify_content_start gap_10 align_item_start mgn-up-10">
                                                    <div class="concept">
                                                        <ix:nonNumeric>Change</ix:nonNumeric>
                                                    </div>
                                                    <div class="content_label">
                                                        <ng-container
                                                            *ngIf="(getDisplayContent(dataPoint['name'])).number">
                                                            <ix:nonNumeric>No prior fact in this report</ix:nonNumeric>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="!(getDisplayContent(dataPoint['name'])).number">
                                                            <ix:nonNumeric>{{dataPoint['name'].change ?
                                                                dataPoint['name'].change : 'n/a'}}</ix:nonNumeric>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify_content_start gap_10 align_item_start mgn-up-10">
                                                    <div class="concept">
                                                        <ix:nonNumeric>Entity</ix:nonNumeric>
                                                    </div>
                                                    <div class="content_label">
                                                        <ix:nonNumeric>{{dataPoint['dataPointId']}}</ix:nonNumeric>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify_content_start gap_10 align_item_start mgn-up-10">
                                                    <div class="concept">
                                                        <ix:nonNumeric>Concept</ix:nonNumeric>
                                                    </div>
                                                    <div class="fw_400 d-flex" style="width: 255px;">
                                                        <div class="company-topic-name">
                                                            <ix:nonNumeric>esrs:{{dataPoint['name']}}</ix:nonNumeric>
                                                        </div>
                                                        <div>
                                                            <mat-icon (click)="copyToClipboard(dataPoint['name'])"
                                                                style="color: #0094ff; font-size: 16px;padding: 1px 5px;"
                                                                [matTooltip]="copiedText ? 'Copied: ' + copiedText : 'Copy'">
                                                                file_copy
                                                            </mat-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <ng-container>
                        <div *ngFor="let section of filterSections" class="mgn-up-20">
                            <div class="d-flex filter-label">
                                <mat-icon (click)="togglefilterSection(section)">
                                    {{ section.show ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                                </mat-icon>
                                <div class="xbrl_element">
                                    <ix:nonNumeric>{{ section.name }}</ix:nonNumeric>
                                </div>
                            </div>
                            <ng-container *ngIf="section.show">
                                <ng-container *ngIf="section.list && section.list.length === 0">
                                    <div [ngClass]="{'expanded': section.show}">
                                        <div class="xbrl_element issueText">
                                            <ix:nonNumeric>{{ section.noIssueMessage }}</ix:nonNumeric>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="section.list && section.list.length > 0">
                                    <div *ngFor="let item of section.list">
                                        <div class="search-filter_data-bg topic_name_section">
                                            <ix:nonNumeric>

                                                <ng-container *ngIf="section.name === 'References'">
                                                    <div class="d-flex gap_10 justify_content_start">
                                                        <div class="refrences_label">
                                                            <ix:nonNumeric>Name</ix:nonNumeric>
                                                        </div>
                                                        <div>
                                                            <ix:nonNumeric> {{ item.name }}</ix:nonNumeric>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex gap_10 justify_content_start">
                                                        <div class="refrences_label">
                                                            <ix:nonNumeric> Number</ix:nonNumeric>
                                                        </div>
                                                        <div>
                                                            <ix:nonNumeric> {{ item.number }}</ix:nonNumeric>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex gap_10 justify_content_start">
                                                        <div class="refrences_label">
                                                            <ix:nonNumeric>Paragraph</ix:nonNumeric>
                                                        </div>
                                                        <div>
                                                            <ix:nonNumeric> {{ item.paragraph }}</ix:nonNumeric>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex gap_10 justify_content_start">
                                                        <div class="refrences_label">
                                                            <ix:nonNumeric>Section</ix:nonNumeric>
                                                        </div>
                                                        <div>
                                                            <ix:nonNumeric>{{ item['entity'] }}</ix:nonNumeric>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="section.name === 'Sections'">
                                                    <ix:nonNumeric> [{{ item['dataPointId'] }}] {{
                                                        item['name'].replace(item['dataPointId'] + ' ', '') }}
                                                    </ix:nonNumeric>
                                                </ng-container>
                                            </ix:nonNumeric>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!searchListShowHide">
                <div esrs:noFactAvailable class=no_fact_bg>
                    <div> <mat-icon class="custom_fact_icon">turned_in_not</mat-icon></div>
                    <div class="fs_18">
                        <ix:nonNumeric>No Fact Selected</ix:nonNumeric>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</ix:tag>
</section>
